// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.label-all {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.labels-head {
  width: 100%;
}
.dropdown-send-item {
  width: 250px;
}
.scheduled-message-btn {
  position: absolute;
  top: 15px;
  right: 75px;
  cursor: pointer;
  font-size: 8pt;
}
.improovment-text-btn {
  position: absolute;
  top: 15px;
  right: 160px;
  cursor: pointer;
  font-size: 8pt;
}
.datepicker-notshow {
  position: absolute;
  top: 15px;
  right: 115px;
  width: 50px;
  display: hidden;
}
.datepicker-notshow div div input,
.datepicker-notshow div div div svg {
  display: none;
}
.scheduled-messages {
  border: 1px solid #e8efff;
  border-radius: 10px;
  background-color: #f4f4fa;
  padding: 20px;
  margin-top: 20px;
}
.darkmode .scheduled-messages {
  border-color: #4d5a66;
  background-color: #303841;
}
.btn-scheduled {
  position: absolute;
  bottom: 90px;
}
#placeholder-loading {
  background-color: black;
  opacity: 0.8;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  font-size: 4em;
  font-weight: bold;
  color: #252a30;
  justify-content: center;
  align-items: center;
  display: none;
}
.darkmode .sub {
  background-color: #8480ff;
}
.sub {
  border-radius: 50%;
  background-color: #5a078b;
  width: 42px;
  height: 42px;
  cursor: pointer;
  padding-bottom: 0;
  color: #e9edef;
  font-weight: 500;
  font-size: 0.875rem;
  margin-left: 30px;
  padding-left: 11px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
