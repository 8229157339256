<script lang="ts">
import EmojiPicker from "vue3-emoji-picker";
import "../../assets/css/magic.css";

import ReplyItemComponent from "../items/replyItemComponent.vue";
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import ModalForwardMessage from "./modal-forward-message.vue";
import ModalReactMessage from "./modal-react-message.vue";
import ModalSendLocation from "./modal-send-location.vue";
import ModalSendContact from "./modal-send-contact.vue";
import ModalDelMessage from "./modal-del-message.vue";
import ChatMessage from "./Messages/ChatMessage.vue";
import ModalEditLabel from "./modal-edit-label.vue";
import ModalSendItem from "./modal-send-item.vue";
import ModalSendPoll from "./ModalSendPoll.vue";
import VueBasicAlert from "vue-basic-alert";
import VueDatePicker from "@vuepic/vue-datepicker";
import ModalSearchMessages from "./ModalSearchMessages.vue";
import BtnScrollDown from "./Buttons/BtnScrollDown.vue";

import { promptSendDocument } from "../../helpers/alertSwal";
import { sendFile, sendText, sendPtt } from "../../helpers/message";
import { Companies } from "../../stores/Companies";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { Recorder } from "../../helpers/audioRecorder";
import { useStore } from "../../stores/dataStore";
import { isMobile } from "../../helpers/funcs";
import { socket } from "../../service/socket";
import { config } from "../../public/config";
import { api } from "../../service/api";
import { Message } from "../../models/message.model";
import { defineComponent } from "vue";
import moment from "moment";

import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import "vue3-emoji-picker/css";
import "@vuepic/vue-datepicker/dist/main.css";
import { generateWhatsAppMsgId } from "../../helpers";
import { Contact } from "../../types/Contact";
import { PropType } from "vue";

export default defineComponent({
  props: {
    chat_opened: {
      type: Object as PropType<Contact>,
      required: true,
    },
    hideActionsChat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    PerfectScrollbar,
    ChatMessage,
    ReplyItemComponent,
    ModalForwardMessage,
    ModalSendItem,
    ModalSendContact,
    ModalSendLocation,
    ModalDelMessage,
    ModalReactMessage,
    ModalSendPoll,
    ModalEditLabel,
    EmojiPicker,
    VueBasicAlert,
    VueDatePicker,
    ModalSearchMessages,
    BtnScrollDown,
  },
  setup() {
    const data = useStore();

    return {
      data,
      Store: () => useStore(),
      sendFile,
      isMobile,
      defaultPic: userNoPhotoUrl,
      moment,
      Companies,
    };
  },
  mounted() {
    this.typing = false;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const pasteListener = function (thePasteEvent: any) {
      // use event.originalEvent.clipboard for newer chrome versions
      const items = (
        thePasteEvent.clipboardData ||
        (thePasteEvent as any).originalEvent.clipboardData
      ).items;
      if (items) {
        //for (var i = 0; i < items.length; i++) {
        //if (items[i].kind === 'file') {
        if (items[0].kind === "file") {
          thePasteEvent.preventDefault();
          const file = items[0].getAsFile();
          //this.file.config(file);

          self.sendWhatsAppImage(file);
        }
        //}
      }
    };
    window.removeEventListener("paste", pasteListener);
    window.addEventListener("paste", pasteListener, false);
    this.recorder.on("record-ended", (data, file, duration) => {
      sendPtt(this.chat_opened.id_api, this.chat_opened.id, file as any, {
        contact: this.chat_opened as any,
        replyData: this.reply,
        contact_type: this.chat_opened.contact_type,
        duration: duration,
        dateScheduled:
          typeof this.dateScheduled != "number"
            ? this.dateScheduled
            : undefined,
      });
      this.reply = "";
      this.dateScheduled = 0;
    });
    const callchatToBottom = async (data: any) => {
      await this.chatToBottom(data);
    };

    socket.on("onmessage_person", async function (data) {
      if (window.location.href.indexOf("index") != -1) {
        await callchatToBottom(data);
      }
    });
    socket.on("onmessage_group", async function (data) {
      if (window.location.href.indexOf("grupos") != -1) {
        await callchatToBottom(data);
      }
    });
  },
  data() {
    return {
      $api: this.$api,
      apiURL: config.$api_url + "/",
      message: [],
      type: "chat",
      message_caption: "",
      image_url: "",
      reply: {
        msg: {} as any,
      } as any,
      recorder: new Recorder(),
      upload_data: "",
      typing: false,

      disableLoadMsg: [] as any,
      isLoadingMessages: [] as any,
      scrollTop: 0,
      scrollHeight: 0,
      delMessage: {
        chatId: "",
        msgId: "",
        chatType: "",
        contactId: 0,
        store: null,
      },
      showScheduled: false,

      react: {
        msgId: "",
        string: "",
        chatId: "",
        contactId: 0,
        key: null,
      },
      dateScheduled: 0,
      showEmojiPicker: false,
      groupNames: {
        recent: "Recentes",
        smileys_people: "Smiles & Pessoas",
        animals_nature: "Natureza",
        food_drink: "Comida e Bebida",
        activities: "Atividades",
        travel_places: "Viagens",
        objects: "Objetos",
        symbols: "Símbolos",
        flags: "Bandeiras",
      },
      showBtnPushDown: false,
      pauseAudio: false,
      msgIdForAutoplay: "",
    };
  },
  methods: {
    setNowIfNow(modelDate: any) {
      const model = moment(modelDate);
      const actual = moment();
      const same = model.isSame(actual, "minutes");
      if (same) this.dateScheduled = 0;
    },
    openUploadInput() {
      document.getElementById("chatUpload")?.click();
    },
    onSelectEmoji(emojiObject: any) {
      this.message_caption += emojiObject.i;
      document.getElementById("inputText")?.focus();
    },
    heightTextarea(e?: any) {
      const inputText = document.getElementById("inputText") as any;
      if (!e) {
        inputText.style.height = "60px";
        return true;
      } else if (
        !(e.target.scrollHeight < 61) &&
        !(e.target.scrollHeight > 200)
      ) {
        inputText.style.height = "auto";
        inputText.style.height = e.target.scrollHeight + "px";
      }
      if (e.target.value == "") {
        inputText.style.height = "auto";
        inputText.style.height = "60px";
      }
    },
    setNullChatOpened() {
      this.$emit("nullchatOpened");
    },
    setFalseTyping() {
      this.typing = false;
    },
    setReply(msg: string) {
      this.reply = msg;
      document.getElementById("inputText")?.focus();
    },
    handleSendMessage(e: any) {
      this.showScheduled = false;
      this.typing = false;
      this.showEmojiPicker = false;
      e.preventDefault();
      this.heightTextarea();
      if (!this.message_caption.trim()) return;
      this.replaceTextShorts();
      let lastMsg = null;
      if (this.chat_opened.Messages.length > 0) {
        const messages = this.chat_opened.Messages;
        if (messages[messages.length - 1].type == "chat") {
          lastMsg = messages[messages.length - 1];
        }
      }
      sendText(this.message_caption, {
        replyData: this.reply,
        lastMsg: lastMsg as any,
        contact_type: this.chat_opened.contact_type,
        contact: this.chat_opened as any,
        dateScheduled:
          typeof this.dateScheduled != "number"
            ? this.dateScheduled
            : undefined,
        msgId:
          this.chat_opened.contact_type === "telegram_bot"
            ? ((parseInt((lastMsg as any)?.msgId || 0) + 1) as any)
            : undefined,
      });
      this.message_caption = "";
      this.reply = "";
      this.dateScheduled = 0;
      this.bottomScroolbar("chat_messages");
    },
    closeForward() {
      this.data.modalForward = false;
      this.data.forward_chatsId = [];
      this.data.forward_contactIds = [];
      this.data.forward_msgIds = [];
      this.data.forward_openChat = true;
      this.data.forward_chatType = "";
    },
    // Audio Recorder Scripts
    destroyRecorder() {
      if (this.recorder.isRecording) {
        $(".sidebar-body .user-list li").css({ "pointer-events": "auto" });
        this.recorder.destroy();
        api.post("/chat/status-recording", {
          channelType: this.chat_opened?.contact_type,
          chatId: this.chat_opened.id_api,
          value: false,
        });
      }
    },
    stopRecorder() {
      //Auto send message by event on 'record-ended'
      if (this.recorder.isRecording) {
        $(".sidebar-body .user-list li").css({ "pointer-events": "auto" });
        this.recorder.stop();
        api.post("/chat/status-recording", {
          channelType: this.chat_opened?.contact_type,
          chatId: this.chat_opened.id_api,
          value: false,
        });
      }
    },
    buttomRecorderStartStop() {
      $(".sidebar-body .user-list li").css({ "pointer-events": "none" });
      this.recorder.start();
      api.post("/chat/status-recording", {
        channelType: this.chat_opened?.contact_type,
        chatId: this.chat_opened.id_api,
        value: true,
      });
      this.typing = true;
    },
    checkShowFastMsg(e: any) {
      //Dando start para poder informar que estamos digitando
      if (!this.typing) {
        api.post("/chat/typing/" + this.chat_opened.id_api, {
          channelType: this.chat_opened.contact_type,
          contactId: this.chat_opened.id,
        });
        this.typing = true;
      }

      const inputText = document.getElementById("inputText") as any;
      const fastmsg_div = document.getElementById("fastMsgDiv") as any;

      // Escondendo a div de fastmsg caso tenha apagado o #
      if (!inputText.value.includes("#")) {
        fastmsg_div.style.visibility = "hidden";
      }
      // Fazendo um filtro na Div para mostrar após o #
      if (inputText.value.includes("#")) {
        const value = inputText.value.split("#")[1].toLowerCase();

        if (value.includes(" ")) {
          fastmsg_div.style.visibility = "hidden";
        } else {
          /*$("#fastMsgDiv ul li").filter(function () {
            $(this).toggle(
              $(this).text().toLowerCase().replace("-", "").indexOf(value) > -1
            );
          });*/
        }
      }

      // Mostrando a div com os fastmsg shurtcuts
      if (e.key == "#") {
        fastmsg_div.style.visibility = "visible";
      }

      if (e.key == "ArrowUp") {
        e.preventDefault();
        $("#fastMsgDiv ul li:first").focus();
      }
      if (e.key == "ArrowDown") {
        e.preventDefault();
        $("#fastMsgDiv ul li:eq(1)").focus();
      }
    },
    setFastMsg(msg: any, fastmsg: any) {
      if (fastmsg.type == "buttons") {
        this.$emit(
          "callsendButtons",
          this.chat_opened.contact_type,
          0,
          this.chat_opened.id_api,
          this.chat_opened.id,
          fastmsg.title,
          fastmsg.footer,
          fastmsg.msg,
          fastmsg.buttons,
          this.reply
        );
        const fastmsg_div = document.getElementById("fastMsgDiv") as any;
        fastmsg_div.style.visibility = "hidden";
        document.getElementById("inputText")?.focus();
        this.message_caption = "";
        return true;
      }
      const value = this.message_caption.split("#")[1].toLowerCase();
      this.message_caption = this.message_caption.replace("#" + value, msg);

      // Organização de Divs
      const fastmsg_div = document.getElementById("fastMsgDiv") as any;
      fastmsg_div.style.visibility = "hidden";
      document.getElementById("inputText")?.focus();
    },
    bottomScroolbar(divId: string) {
      setTimeout(() => {
        const objDiv = document.getElementById(divId) as any;
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 50);
    },

    replaceTextShorts() {
      // Remove \n do inicio e do final da string
      this.message_caption = this.message_caption.replace(/^\s+|\s+$/g, "");
      const user_name = /{{atendente.nome}}/g;
      this.message_caption = this.message_caption.replace(
        user_name,
        this.data.user.name
      );

      const customer_name = /{{cliente.nome}}/g;
      this.message_caption = this.message_caption.replace(
        customer_name,
        this.chat_opened.name || ""
      );
    },
    handleAttachFile() {
      const upload = this.$refs.chatUpload as any;
      const file = upload.files[0];
      this.sendWhatsAppImage(file);
    },
    handleDropFile(ev: any) {
      ev.preventDefault();

      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === "file") {
            const file = ev.dataTransfer.items[i].getAsFile();
            this.sendWhatsAppImage(file);
          }
        }
      } else {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          const file = ev.dataTransfer.files[i];
          this.sendWhatsAppImage(file);
        }
      }
    },
    async sendWhatsAppImage(fileput: any) {
      $(document.body).focus();
      const upload = this.$refs.chatUpload as any;
      let file = upload.files[0] as any;
      if (fileput) file = fileput;

      let type = "document";
      if (file.type.includes("image")) type = "image";
      else if (file.type.includes("audio")) type = "audio";
      else if (file.type.includes("video")) type = "video";

      await promptSendDocument(
        file,
        type,
        this.message_caption,
        this.chat_opened.contact_type as string
      )
        .then((result) => {
          this.$emit(
            "callsendFile",
            this.chat_opened.contact_type as string,
            0,
            type,
            this.chat_opened.id_api as string,
            this.chat_opened.id as number,
            file as any,
            result.caption,
            result.base64,
            this.reply,
            false
          );
          this.reply = "";
          this.message_caption = "";
          upload.value = null;
        })
        .catch(() => {
          upload.value = "";
        });
    },
    showInfoChat() {
      const chat_bar = $(".chat-messages").width() as unknown as HTMLElement;
      const has = $(".right-sidebar").hasClass("show-right-sidebar");
      if (has) {
        $(".right-sidebar").addClass("hide-right-sidebar");
        $(".right-sidebar").removeClass("show-right-sidebar");
        if (window.innerWidth > 991 && window.innerWidth < 1201) {
          $(".chat-messages").css("margin-left", 0);
        }
        if (window.innerWidth < 992) {
          $(".chat-messages").removeClass("hide-chatbar");
        }
        return true;
      }
      $(".right-sidebar").addClass("show-right-sidebar");
      $(".right-sidebar").removeClass("hide-right-sidebar");
      if (window.innerWidth > 991 && window.innerWidth < 1201) {
        $(".chat-messages:not(.right-sidebar .chat)").css(
          "margin-left",
          -chat_bar
        );
      }
      if (window.innerWidth < 992) {
        $(".chat-messages:not(.right-sidebar .chat)").addClass("hide-chatbar");
      }
    },
    backButton() {
      const chat = document.getElementsByClassName("chat-messages")[0];
      const leftSidebar = document.getElementsByClassName("left-sidebar")[0];
      const sidebarMenu = document.getElementsByClassName("sidebar-menu")[0];
      (leftSidebar as any).style.display = "block";
      (sidebarMenu as any).style.display = "block";
      (chat as any).style.display = "none";
    },
    updateName() {
      const name = $("#nameEditable").text();
      // eslint-disable-next-line vue/no-mutating-props
      this.chat_opened.name = name;
      api.post("/contacts/upgrade/name", {
        contact_type: this.chat_opened?.contact_type,
        idApi: this.chat_opened.id_api,
        name: name,
      });
    },
    getThumb(url: string) {
      let urlParse = url;
      if (!url.includes(".jpg") && !url.includes(".jpeg")) {
        urlParse = url.replace(/\.[0-9a-z]+$/i, ".png");
      }
      urlParse = urlParse.replace("image/", "image/_thumb/");
      urlParse = urlParse.replace("pdf/", "pdf/_thumb/");
      urlParse = urlParse.replace("video/", "video/_thumb/");
      return this.apiURL + urlParse;
    },

    chatToBottom(data: any) {
      // Descer chat para ver as novas mensagens
      if (this.chat_opened) {
        if (this.chat_opened.id_api == data.id_api) {
          const objDiv = document.getElementById(
            "chat_messages"
          ) as unknown as HTMLElement;
          if (objDiv.scrollTop - objDiv.scrollHeight < 300) {
            this.bottomScroolbar("chat_messages");
          }
        }
      }
    },

    getContactCustomDatas(dataName: string) {
      if (!this.chat_opened) return true;
      if (!this.chat_opened.customdatas) return "";
      const customdatas = JSON.parse(this.chat_opened.customdatas);
      if (!dataName) return customdatas;
      let value = "";
      if (dataName) {
        customdatas.map((cdt: any) => {
          if (cdt.name == dataName) {
            value = cdt.value;
          }
        });
      }
      return value;
    },
    checkScroll(e: any) {
      this.scrollTop = e.target.scrollTop;
      this.scrollHeight = e.target.scrollHeight;
      if (e.target.scrollTop < 500) this.loadLastMessages();
      e.target.scrollHeight - e.target.scrollTop > 2000
        ? (this.showBtnPushDown = true)
        : (this.showBtnPushDown = false);
    },
    getMessagesInChat(msgId: string, id: number) {
      const loading = document.getElementById("placeholder-loading");
      loading!.style!.display = "flex";
      const element = document.getElementById("content-" + msgId);
      if (element) {
        element.animate(
          [
            { opacity: "0.4" },
            { transform: "translateY(0px)" },
            { transform: "translateY(-20px)" },
            { opacity: "1" },
          ],
          {
            duration: 300,
            iterations: 3,
          }
        );
        element.scrollIntoView({ block: "center", behavior: "instant" });
        loading!.style!.display = "none";
        return;
      }
      //this.loadLastMessages();

      setTimeout(async () => {
        try {
          const { data } = await api.get(
            `/messages-from-id/whatsapp/${this.chat_opened.id_api}/${id}`
          );
          (this.chat_opened as any).Messages = data.slice().reverse();
        } catch (error) {
          (this.$refs as any).alert.showAlert(
            "error",
            "Erro ao de comunicação com o servidor",
            "Erro",
            {
              iconSize: 35,
              iconType: "solid",
              position: "bottom right",
            }
          );
        }
        (this.$refs.scroll as any).$el.scrollTop = 0;
        this.getMessagesInChat(msgId, id);
      }, 1000);
    },
    async loadLastMessages() {
      if (
        this.disableLoadMsg[this.chat_opened.id] ||
        this.isLoadingMessages[this.chat_opened.id]
      )
        return;
      this.isLoadingMessages[this.chat_opened.id] = true;
      const messagges = this.chat_opened.Messages || [];
      const chat = this.chat_opened;
      const count = messagges.length;
      let lastMsg = null;

      if (messagges.length > 0) {
        const messages = this.chat_opened.Messages;
        for (let index = 0; index < messages.length; index++) {
          const element = messages[index];
          if (
            element.type == "chat" ||
            element.type == "image" ||
            element.type == "video" ||
            element.type == "document"
          ) {
            lastMsg = element.msgId;
            break;
          }
        }
      }
      const loadMsg = document.getElementById(
        "styleLoadLastMessages"
      ) as unknown as any;
      loadMsg.innerHTML =
        '<span class="material-icons">sync</span> Carregando...';
      try {
        await this.data.loadLastMessagesInActiveChat(
          this.chat_opened.id,
          this.chat_opened.Messages.length > 0
            ? this.chat_opened.Messages[0]?.id
            : undefined,
          20
        );
        this.isLoadingMessages[this.chat_opened.id] = false;
        loadMsg.innerHTML =
          '<span class="material-icons">autorenew</span> Carregar mensagens anteriores';
        const ctt = await this.data.getContactById(chat.id);
        if (!ctt) return;
        const zero = JSON.parse(this.scrollTop.toString());
        const zeroHeight = JSON.parse(this.scrollHeight.toString());
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        setTimeout(() => {
          for (const msg of messagges) {
            const divContent = document.getElementById(`content-${msg.msgId}`);
            if (divContent) {
              divContent.scrollIntoView();
              break;
            }
          }
          /*
          const diff =
            (that.$refs.scroll as any)?.$el?.scrollHeight || 0 - zeroHeight;
          (that.$refs.scroll as any).$el.scrollTop = diff + zero;
          */
        }, 50);
      } catch (error) {
        if (error === "NotHaveMoreMessages") {
          (this.$refs as any).alert.showAlert(
            "info",
            "Não há mais mensagens nessa conversa",
            "Sem mensagens",
            {
              iconSize: 35,
              iconType: "solid",
              position: "bottom right",
            }
          );
        } else {
          (this.$refs as any).alert.showAlert(
            "error",
            "Erro ao buscar mensagens antigas",
            "Erro",
            {
              iconSize: 35,
              iconType: "solid",
              position: "bottom right",
            }
          );
          throw new Error(error as any);
        }
        loadMsg.innerHTML =
          '<span class="material-icons">autorenew</span> Carregar mensagens anteriores';
        this.disableLoadMsg[this.chat_opened?.id] = false;
        this.isLoadingMessages[this.chat_opened?.id] = false;
      }
    },

    setdelMessage(
      chatId: string,
      msgId: string,
      chatType: any,
      contactId: number,
      store: Message
    ) {
      this.delMessage.chatId = chatId;
      this.delMessage.msgId = msgId;
      this.delMessage.chatType = chatType;
      this.delMessage.contactId = contactId;
      this.delMessage.store = store as any;
    },

    async deletarMensagem() {
      /*await api.post(
        `message/delete/${this.delMessage.chatId}/${this.delMessage.msgId}`,
        { chatType: this.delMessage.chatType }
      );
      this.data.editMessage(this.delMessage.contactId, this.delMessage.msgId, {
        ack: "-8",
      });*/
      this.delMessage = {
        chatId: "",
        msgId: "",
        chatType: "",
        contactId: 0,
        store: null,
      };
    },
    sendReaction(string: string) {
      const msgId = generateWhatsAppMsgId("");
      this.data.addReactionMsg(
        this.react.msgId,
        string,
        this.chat_opened as any,
        msgId
      );
      api
        .post("message/send-reaction", {
          channelType: this.chat_opened?.contact_type,
          id: msgId,
          key: this.react.key,
          chatId: this.react.chatId,
          contactId: this.react.contactId,
          string: string,
          readKeys: this.chat_opened?.Messages.map((i: any) => {
            return i.messageSocket?.key || undefined;
          }),
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setReaction(id: string, chatId: string, contactId: number, key: any) {
      this.react.msgId = id;
      this.react.chatId = chatId;
      this.react.contactId = contactId;
      this.react.key = key;
    },
    getLabels(): any {
      const labels: any = [];
      this.data.labels.map((lbl: any) => {
        if (this.chat_opened.labels) {
          if (parseInt(this.chat_opened.labels) == parseInt(lbl.id)) {
            (labels as any).push(lbl);
          }
        }
      });
      return labels;
    },
    getFontColor(color: string) {
      const hex = color.replace("#", "");
      const c_r = parseInt(hex.substr(0, 2), 16);
      const c_g = parseInt(hex.substr(2, 2), 16);
      const c_b = parseInt(hex.substr(4, 2), 16);
      const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
      if (brightness > 155) {
        return "#00000";
      } else {
        return "#FFF";
      }
    },
    deleteLabel() {
      // eslint-disable-next-line vue/no-mutating-props
      this.chat_opened.labels = "";

      api.post("/contacts/update/labels", {
        contact_type: this.chat_opened?.contact_type,
        idApi: this.chat_opened.id_api,
        labels: "",
      });
    },
    triggerDatePicker() {
      const dt = this.$refs?.datepicker as any;
      this.dateScheduled = moment().toDate() as any;
      dt?.openMenu();
    },
    toggleScheduled() {
      const ico = document.getElementById("ico-scheduled");
      this.showScheduled = !this.showScheduled;
      if (this.showScheduled) {
        ico?.classList.remove("fa-arrow-up");
        ico?.classList.add("fa-arrow-down");
      } else {
        ico?.classList.remove("fa-arrow-down");
        ico?.classList.add("fa-arrow-up");
      }
      this.bottomScroolbar("chat_messages");
    },
    scrollDown() {
      const el = document.getElementById("footer-inside");
      el?.scrollIntoView();
    },
    async improovmentText() {
      const input = document.getElementById("inputText");
      if (this.message_caption.length < 10 || !input) return;
      try {
        input.style.opacity = "0.5";
        input.style.pointerEvents = "none";
        const { data } = await api.post(`/ia/improovment-text`, {
          text: this.message_caption,
        });
        this.message_caption = data;
        this.heightTextarea();
        input.classList.add("magictime", "puffIn");
        input.style.opacity = "1";
        input.style.pointerEvents = "auto";
        setTimeout(() => {
          input.classList.remove("magictime", "puffIn");
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    getScheduledMessages() {
      return (
        this.data.openedChat?.Messages?.filter(
          (msg: any) => msg.dateScheduled
        ) || []
      );
    },
    getMyFastMsg() {
      const mydpt = this.data.departments.filter((dpt) =>
        dpt.users.includes(this.data?.user?.id?.toString())
      );
      const idsDpt: any = [];
      mydpt.map((dpt: any) => {
        (idsDpt as any).push(dpt.id);
      });
      return this.data.fastmsgs.filter(
        (fmsg) =>
          fmsg.departmentId.includes(idsDpt) ||
          fmsg.departmentId.includes("all")
      );
    },
    chatIsGroup() {
      return this.chat_opened?.id_api?.includes("@g.us");
    },
    groupParticipants() {
      return JSON.parse(this.chat_opened.participants || "");
    },
    groupQntParticipants() {
      const participants = JSON.parse(this.chat_opened.participants || "");
      if (!participants) return 0;
      return participants?.length - 3;
    },
  },
});
</script>

<template>
  <div id="placeholder-loading">Por favor, aguarde...</div>
  <vue-basic-alert :duration="300" :closeIn="4000" ref="alert" />
  <div
    class="chat chat-messages"
    id="chat-panel"
    v-show="Store().openedChat && Store()?.openedChat?.isOpen == true"
    v-on:drop="handleDropFile"
  >
    <div>
      <perfect-scrollbar
        id="chat_messages"
        class="scroll-area"
        ref="scroll"
        @scroll="checkScroll"
      >
        <div class="chat-header">
          <div class="user-details">
            <div class="d-lg-none ms-2">
              <ul class="list-inline mt-2 me-2">
                <li class="list-inline-item">
                  <a
                    class="text-muted px-0 left_side"
                    data-chat="open"
                    v-on:click="backButton"
                  >
                    <i class="fas fa-arrow-left"></i>
                  </a>
                </li>
              </ul>
            </div>
            <figure class="avatar ms-1">
              <img
                v-lazy="{
                  src: !chat_opened?.profilePicUrl
                    ? defaultPic
                    : chat_opened?.profilePicUrl,
                  error: defaultPic,
                }"
                class="rounded-circle"
                alt="image"
              />
            </figure>
            <div class="mt-1">
              <h5
                contentEditable="true"
                id="nameEditable"
                @keydown.tab="updateName"
                v-on:blur="updateName"
                spellcheck="false"
                v-html="chat_opened?.name"
              ></h5>
              <span v-if="data.userisMobile" v-on:click="showInfoChat"
                >Mais informações<br
              /></span>
              <small
                class="online"
                v-if="chat_opened?.isOnline && !chatIsGroup"
              >
                Online<br />
              </small>

              <small
                v-if="!chatIsGroup && chat_opened?.contact_type === 'whatsapp'"
              >
                {{
                  chat_opened?.id_api?.replace(
                    /([0-9]{2})?([0-9]{2})?([0-9]{4,5})?([0-9]{4})(@s\.whatsapp\.net)/,
                    "($2) $3-$4"
                  )
                }}
              </small>
              <small v-if="chat_opened?.companyId">
                - {{ Companies().getById(chat_opened.companyId)?.fantasyName }}
              </small>
              <small
                v-if="
                  !chatIsGroup && chat_opened?.contact_type === 'telegram_bot'
                "
              >
                @{{ chat_opened?.pushname }}
              </small>
              <small
                v-for="question in data.getMasterCustomDatas()"
                v-bind:key="question.key"
              >
                <template v-if="question.showchat">
                  <template v-if="getContactCustomDatas(question.name)">
                    – <b>{{ question.name }}</b
                    >: {{ getContactCustomDatas(question.name) }}
                  </template>
                </template>
              </small>
            </div>

            <div class="avatar-group" v-if="chatIsGroup">
              <div
                v-for="ptpt in groupParticipants?.slice(0, 3)"
                v-bind:key="ptpt.id"
              >
                <div class="avatar avatar-xs group_img group_header">
                  <img
                    class="avatar-img rounded-circle"
                    :alt="ptpt?.name"
                    v-lazy="{
                      src: defaultPic,
                      error: defaultPic,
                    }"
                  />
                </div>
              </div>
              <div
                class="avatar avatar-xs group_img group_header"
                v-if="groupQntParticipants > 0"
              >
                <span class="avatar-title rounded-circle"
                  >+{{ groupQntParticipants }}</span
                >
              </div>
            </div>
          </div>
          <div class="chat-options" v-if="chat_opened">
            <ul class="list-inline">
              <li
                v-if="!data.userisMobile"
                class="list-inline-item"
                data-bs-toggle="modal"
                data-bs-target="#serch-messages"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Buscar"
                  href="javascript:void(0)"
                  class="btn btn-outline-light chat-search-btn"
                >
                  <i class="fas fa-search"></i>
                </a>
              </li>
              <li
                v-if="
                  !data.userisMobile &&
                  !chatIsGroup &&
                  data.hasPermission('transfer-chats')
                "
                @click="data.chatIdTransfer = chat_opened"
                class="list-inline-item dream_profile_menu"
                data-bs-toggle="modal"
                data-bs-target="#transfer-chat"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a>
                  <i
                    class="fas fa-random"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Transferir"
                  ></i>
                </a>
              </li>
              <li
                @click="showInfoChat"
                v-show="!data.userisMobile"
                class="list-inline-item dream_profile_menu"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Perfil"
              >
                <a href="javascript:void(0)" class="btn btn-outline-light">
                  <i class="fas fa-user"></i>
                </a>
              </li>
              <li
                v-show="!data.userisMobile"
                @click="setNullChatOpened"
                class="list-inline-item dream_profile_menu"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Fechar"
              >
                <a href="javascript:void(0)" class="btn btn-outline-light">
                  <i class="fas fa-times-circle"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- Chat Search -->
          <div class="chat-search" v-if="chat_opened">
            <form>
              <span class="fas fa-search form-control-feedback"></span>
              <input
                type="text"
                name="chat-search"
                placeholder="Search Chats"
                class="form-control"
              />
              <div class="close-btn-chat">
                <span class="material-icons">close</span>
              </div>
            </form>
          </div>
          <!-- /Chat Search -->

          <div class="row label-all">
            <template v-if="chat_opened?.labels">
              <div
                v-for="label in getLabels()"
                class="labels-head col-12"
                v-bind:key="label.id"
              >
                <div
                  :style="
                    'background-color:' +
                    label.color +
                    ';color:' +
                    getFontColor(label.color)
                  "
                  class="label-tag"
                  :title="label.name"
                ></div>
              </div>
            </template>
          </div>
        </div>
        <div class="chat-body" v-on:drop="handleDropFile">
          <div class="chat-line">
            <span
              class="chat-date"
              style="cursor: pointer"
              :style="{ 'margin-top': data.userisMobile ? '90px' : '0px' }"
              v-on:click="loadLastMessages"
              id="styleLoadLastMessages"
              ><span class="material-icons">autorenew</span> Carregar mensagens
              anteriores</span
            >
          </div>
          <ChatMessage
            v-for="(msg, index) in chat_opened?.Messages?.filter(
              (msg) => !msg.dateScheduled
            )"
            v-bind:key="msg?.msgId"
            @setReply="setReply"
            @handleDropFile="handleDropFile"
            @setdelMessage="setdelMessage"
            @setPause="(value) => (pauseAudio = value)"
            @playAudioByMsgId="(msgId) => (msgIdForAutoplay = msgId)"
            @loadLastMessages="loadLastMessages"
            @setReaction="setReaction"
            :msg_item="msg"
            :chat_opened="chat_opened"
            :index="index"
            :msgIdForAutoplay="msgIdForAutoplay"
            :pause="pauseAudio"
          >
          </ChatMessage>
          <div
            class="scheduled-messages"
            v-show="showScheduled && getScheduledMessages.length > 0"
          >
            <ChatMessage
              v-for="(msg, index) in getScheduledMessages"
              @setReply="setReply"
              @handleDropFile="handleDropFile"
              v-bind:key="msg?.msgId"
              @setdelMessage="setdelMessage"
              @loadLastMessages="loadLastMessages"
              @setReaction="setReaction"
              :msg_item="msg"
              :chat_opened="chat_opened"
              :index="index"
            >
            </ChatMessage>
          </div>
          <div id="footer-inside"></div>
        </div>
      </perfect-scrollbar>
    </div>
    <div
      class="forward_box position-relative p-2 col-12"
      v-if="data.forward_msgIds.length > 0"
    >
      <div class="row">
        <div class="col-10">
          <span
            ><i
              @click="closeForward"
              class="fas fa-times fa-lg btns-rounded-style"
            ></i>
            {{ data.forward_msgIds.length }} selecionadas</span
          >
        </div>
        <div class="col-2 d-flex justify-content-end px-4">
          <i
            class="fas fa-share fa-lg btns-rounded-style"
            v-bind:style="{
              'pointer-events':
                data.forward_msgIds.length > 0 ? 'auto' : 'none',
              opacity: data.forward_msgIds.length > 0 ? 1 : 0.5,
            }"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#forward-message"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
          </i>
        </div>
      </div>
    </div>
    <div class="fastmsg" id="fastMsgDiv" v-if="data.fastmsgs">
      <!-- <a href="javascript:void(0);"
            class="close_profile close_profile4">
            <span class="material-icons" @click="this.hide()">close</span>
        </a> -->
      <ul v-for="fmsg in getMyFastMsg" v-bind:key="fmsg.id">
        <li
          :tabindex="fmsg.id"
          @keyup.enter.exact="setFastMsg(fmsg.msg, fmsg)"
          v-on:click="setFastMsg(fmsg.msg, fmsg)"
        >
          <strong>#{{ fmsg.name }}</strong
          ><br />
          <p>{{ fmsg.msg }}</p>
        </li>
      </ul>
    </div>

    <transition name="nested">
      <reply-item-component
        v-if="reply"
        v-bind:key="reply.msg.id"
        @setNullReply="setReply"
        :reply="reply.msg"
        :chat="chat_opened"
      >
      </reply-item-component>
    </transition>
    <!--
    <div class="message-reply" v-if="reply">
        <a href="javascript:void(0);"
            class="close_profile close_profile4">
            <span class="material-icons" @click="reply = ''">close</span>
        </a>
        <template v-if="chat_opened.sender.name">
            <strong>{{chat_opened.sender.name}}</strong><br/>
            <div v-html="reply.html"></div>
        </template>
    </div> -->

    <div
      class="recording-footer"
      v-if="chat_opened"
      style="z-index: 10; float: left"
    >
      <form
        :name="'sendMessage' + chat_opened?.id_api"
        v-if="this.recorder.isRecording && chat_opened"
      >
        <div class="form-buttons">
          <button
            class="btn cancel-btn"
            @click="destroyRecorder()"
            type="submit"
          >
            <span class="material-icons">close</span>
          </button>
          <button class="btn send-btn" @click="stopRecorder()" type="submit">
            <span class="material-icons">send</span>
          </button>
        </div>
      </form>

      <div id="progress-recorder" class="progress-recorder"></div>
      <div id="wave" class="wave" style="width: 100%"></div>
    </div>
    <div
      class="chat-footer"
      id="referenteChatFooter"
      v-if="chat_opened && !this.recorder.isRecording"
    >
      <div
        class="btn btn-secondary btn-scheduled"
        v-show="getScheduledMessages.length > 0"
        @click="toggleScheduled"
      >
        Mensagens agendadas.
        <i class="fas fa-arrow-up ml-2" id="ico-scheduled"></i>
      </div>
      <form
        :name="'sendMessage' + chat_opened?.id_api"
        v-on:submit="handleSendMessage"
        v-if="!hideActionsChat"
      >
        <div class="smile-col">
          <!--<a href="javascript:void(0);"><i class="far fa-smile  "></i></a> -->
        </div>
        <!--
            <div class="attach-col">
                <a href="javascript:void(0);" v-on:click="handleClickButtonUpload">
                    <input type="file" ref="chatUpload" accept="image/*, audio/*, video/*, document/*, .cdr, .psd, .eps, .doc, .docx, .xls" class="chatUpload" v-on:change="handleAttachFile" id="chatUpload" name="chatUpload">
                    <i class="fas fa-paperclip"></i>
                </a>
            </div>
            -->

        <div class="attach-col">
          <input
            type="file"
            style="display: hidden"
            ref="chatUpload"
            accept="image/*, audio/*, video/*, document/*, .cdr, .psd, .eps, .doc, .docx, .xls,application/pdf,application/vnd.ms-excel"
            class="chatUpload"
            v-on:input="handleAttachFile"
            id="chatUpload"
            name="chatUpload"
          />
          <a href="javascript:void(0);" data-bs-toggle="dropdown">
            <i class="fas fa-paperclip"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg-start dropdown-send-item">
            <a
              class="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#send-contact"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Contato<span class="fas fa-address-card"></span
            ></a>
            <a
              class="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#send-location"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Localização<span class="fas fa-map-marked-alt"></span>
            </a>
            <a class="dropdown-item" @click="openUploadInput">
              Arquivos & Videos<span class="fas fa-file-word"></span>
            </a>
            <a
              class="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#send-poll"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Enquete<span class="fas fa-poll"></span>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item"> Bot<span class="fas fa-robot"></span></a>
            <a class="dropdown-item" disabled="true">
              Pedido<span class="fas fa-file-alt"></span>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" disabled="true">
              Catálogo<span class="fas fa-cart-plus"></span>
            </a>
          </div>
        </div>

        <textarea
          class="form-control chat_form"
          id="inputText"
          spellcheck="false"
          v-if="!this.recorder.isRecording"
          @keyup.esc="showEmojiPicker = false"
          tabindex="0"
          @input="heightTextarea"
          @change="heightTextarea"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="handleSendMessage"
          @keyup="checkShowFastMsg"
          v-model="message_caption"
          placeholder="Digite aqui sua mensagem....."
        >
        </textarea>
        <BtnScrollDown
          v-show="showBtnPushDown"
          @click="scrollDown"
        ></BtnScrollDown>

        <div class="form-buttons">
          <button class="btn send-btn" type="submit">
            <span class="material-icons">send</span>
          </button>
        </div>
        <div
          class="row"
          style="background-color: red"
          @keyup.esc="showEmojiPicker = false"
          tabindex="0"
        >
          <div class="col-12" style="background-color: red">
            <EmojiPicker
              :disable-skin-tones="true"
              v-show="showEmojiPicker"
              :display-recent="true"
              :group-names="groupNames"
              :static-texts="{
                placeholder: 'Busque um emoji',
                skinTone: 'Tom de pele',
              }"
              @select="onSelectEmoji"
            />
          </div>
        </div>
        <div
          class="emoji-col"
          @click="showEmojiPicker = !showEmojiPicker"
          v-if="!data.userisMobile"
        >
          <button
            @keyup.esc="showEmojiPicker = false"
            tabindex="0"
            type="button"
            id="emojiButton"
          >
            😀
          </button>
        </div>

        <div class="specker-col">
          <a
            href="javascript:void(0);"
            v-on:click="buttomRecorderStartStop"
            id="buttomRecorderStartStop"
            ><span class="material-icons">settings_voice</span></a
          >
        </div>
        <VueDatePicker
          v-model="dateScheduled"
          @update:model-value="setNowIfNow"
          id="datePickerScheduledMessage"
          :min-date="new Date()"
          time-picker-inline
          locale="pt"
          ref="datepicker"
          class="datepicker-notshow"
          :clearable="true"
          select-text="Aplicar"
          cancel-text="Fechar"
          now-button-label="Agora"
          :dark="data.darkMode"
          :action-row="{ showNow: true }"
        />
        <div
          class="improovment-text-btn"
          v-show="message_caption.length > 25"
          @click="improovmentText"
        >
          Melhorar texto
        </div>
        <div
          class="scheduled-message-btn"
          v-if="dateScheduled == 0"
          @click="triggerDatePicker"
        >
          Enviar agora
        </div>
        <div class="scheduled-message-btn" v-else @click="triggerDatePicker">
          {{ moment(dateScheduled).format("DD/MM/YY") }} -
          {{ moment(dateScheduled).format("HH:mm") }}
        </div>
      </form>

      <modal-forward-message :chat_opened="chat_opened" />
      <modal-send-item :chat_opened="chat_opened" />
      <modal-edit-label :chat_opened="chat_opened" />
      <modal-send-contact :chat_opened="chat_opened" />
      <modal-del-message
        @deletarMensagem="deletarMensagem"
        :chat="chat_opened"
        :message="delMessage.store"
      />
      <modal-react-message @sendReaction="sendReaction" />
      <ModalSendPoll :chat_opened="chat_opened" />
      <ModalSendLocation :chat="chat_opened" />
      <ModalSearchMessages
        :chat="chat_opened"
        @loadLastMessages="loadLastMessages"
        @getMessagesInChat="getMessagesInChat"
      />
    </div>
  </div>
</template>
<style>
.label-all {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.labels-head {
  width: 100%;
}
.dropdown-send-item {
  width: 250px;
}
.scheduled-message-btn {
  position: absolute;
  top: 15px;
  right: 75px;
  cursor: pointer;
  font-size: 8pt;
}
.improovment-text-btn {
  position: absolute;
  top: 15px;
  right: 160px;
  cursor: pointer;
  font-size: 8pt;
}
.datepicker-notshow {
  position: absolute;
  top: 15px;
  right: 115px;
  width: 50px;
  display: hidden;
}
.datepicker-notshow div div input,
.datepicker-notshow div div div svg {
  display: none;
}
.scheduled-messages {
  border: 1px solid #e8efff;
  border-radius: 10px;
  background-color: #f4f4fa;
  padding: 20px;
  margin-top: 20px;
}
.darkmode .scheduled-messages {
  border-color: #4d5a66;
  background-color: #303841;
}
.btn-scheduled {
  position: absolute;
  bottom: 90px;
}

#placeholder-loading {
  background-color: black;
  opacity: 0.8;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  font-size: 4em;
  font-weight: bold;
  color: #252a30;
  justify-content: center;
  align-items: center;
  display: none;
}
.darkmode .sub {
  background-color: #8480ff;
}
.sub {
  border-radius: 50%;
  background-color: #5a078b;
  width: 42px;
  height: 42px;
  cursor: pointer;
  padding-bottom: 0;
  color: #e9edef;
  font-weight: 500;
  font-size: 0.875rem;
  margin-left: 30px;
  padding-left: 11px;
}
</style>
